<template>
    <ui-create
        name="print"
        :title="title"
        :load="load"
    >
        <template #body >

                <v-form ref="form" >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Descrição"
                                v-model="form.descricao"
                                counter
                                maxlength="64"
                                :rules="[
                                    form_descricao_rules.required, form_descricao_rules.counter,
                                        form_descricao_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Camino da impressora"
                                v-model="form.caminho_impressora"
                                counter
                                maxlength="64"
                                :rules="[
                                    form_caminho_impressora_rules.required, 
                                        form_caminho_impressora_rules.counter,
                                        form_caminho_impressora_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Modelo da Impressora"
                                v-model="form.modelo_impressora"
                                maxlength="64"
                                :rules="[
                                    form_modelo_impressora_rules.required, 
                                        form_modelo_impressora_rules.counter,
                                        form_modelo_impressora_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Tipo"
                                v-model="form.tipo"
                                maxlength="32"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Salto de Página"
                                v-model="form.salto_pagina"
                                maxlength="3"
                                v-mask="'###'"
                                :rules="[form_salto_pagina_rules.nan]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox
                                label="Corte Altomático?"
                                v-model="form.corte_automatico"
                                true-value="s"
                                false-value="n"
                            ></v-checkbox>

                            <v-checkbox
                                label="Desativado?"
                                v-model="form.desativado"
                                true-value="s"
                                false-value="n"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwPrintCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                caminho_impressora: '',
                modelo_impressora: '',
                tipo: null,
                corte_automatico: null,
                desativado: null,
                salto_pagina: ''
            },

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },
            form_caminho_impressora_rules: {
                required: value => !!value || 'Caminho da impressora é obrigatório.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },
            form_modelo_impressora_rules: {
                required: value => !!value || 'Modelo da impressora é obrigatório.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },
            form_salto_pagina_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Setor de Impressão: ${this.id}` : 'Cadastrar Setor de Impressão'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            // if(this.$refs.form.validate()){
                this.load = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            // }
        },

        update: function(){
            this.form.salto_pagina = this.form.salto_pagina === '' ? 0 : this.form.salto_pagina
            this.load = true
            fetch(process.env.VUE_APP_API + '/imp-sector/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar o setor de impressão!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        create: function(){
            this.load = true
            this.form.salto_pagina = this.form.salto_pagina === '' ? 0 : this.form.salto_pagina
            fetch(process.env.VUE_APP_API + '/imp-sector/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Setor de impressão cadastrado com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar o setor de impressão!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        getData: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/imp-sector/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.load = false
                })
        },
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.$root.$on('save-changes-on-print', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>