var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-create',{attrs:{"name":"print","title":_vm.title,"load":_vm.load},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Descrição","counter":"","maxlength":"64","rules":[
                                _vm.form_descricao_rules.required, _vm.form_descricao_rules.counter,
                                    _vm.form_descricao_rules.min
                            ]},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Camino da impressora","counter":"","maxlength":"64","rules":[
                                _vm.form_caminho_impressora_rules.required, 
                                    _vm.form_caminho_impressora_rules.counter,
                                    _vm.form_caminho_impressora_rules.min
                            ]},model:{value:(_vm.form.caminho_impressora),callback:function ($$v) {_vm.$set(_vm.form, "caminho_impressora", $$v)},expression:"form.caminho_impressora"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Modelo da Impressora","maxlength":"64","rules":[
                                _vm.form_modelo_impressora_rules.required, 
                                    _vm.form_modelo_impressora_rules.counter,
                                    _vm.form_modelo_impressora_rules.min
                            ]},model:{value:(_vm.form.modelo_impressora),callback:function ($$v) {_vm.$set(_vm.form, "modelo_impressora", $$v)},expression:"form.modelo_impressora"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Tipo","maxlength":"32"},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"label":"Salto de Página","maxlength":"3","rules":[_vm.form_salto_pagina_rules.nan]},model:{value:(_vm.form.salto_pagina),callback:function ($$v) {_vm.$set(_vm.form, "salto_pagina", $$v)},expression:"form.salto_pagina"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Corte Altomático?","true-value":"s","false-value":"n"},model:{value:(_vm.form.corte_automatico),callback:function ($$v) {_vm.$set(_vm.form, "corte_automatico", $$v)},expression:"form.corte_automatico"}}),_c('v-checkbox',{attrs:{"label":"Desativado?","true-value":"s","false-value":"n"},model:{value:(_vm.form.desativado),callback:function ($$v) {_vm.$set(_vm.form, "desativado", $$v)},expression:"form.desativado"}})],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }